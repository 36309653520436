<template>
  <div class="bottom-bar-container">
    <div class="pusher"></div>
    <div class="bottom-bar" :class="darkMode ? 'dark' : ''">
      <div v-if="$slots.default" class="slot">
        <slot></slot>
      </div>
      <div v-if="$slots.mobile" class="mobileslot">
        <slot name="mobile"></slot>
      </div>
      <div class="buttons" :class="{ grid: back && withContinue }">
        <slot name="buttons"></slot>
        <template v-if="!custom">
          <UIButton ghost class="back" v-if="back" @click="backAction">
            <span v-if="backText">{{ backText }}</span>
            <span v-else>{{ $t('Cancel') }}</span>
          </UIButton>
          <UIButton :ghost="true" class="save-and-exit" v-if="withContinue" @click="saveAndExit">
            <span v-if="withContinue">{{ actionText || $t("Save & Exit") }}</span>
          </UIButton>
          <UIButton
            v-if="!backOnly"
            :to="actionRouterLink"
            :disabled="disabled"
            @click="save"
            class="save"
          >
            <span v-if="!saving && !withContinue">{{ actionText || $t("Save") }}</span>
            <span v-if="!saving && withContinue">{{ continueText || $t("Save & Continue") }}</span>
            <span v-if="saving">{{ savingText || $t("Saving...") }}</span>
          </UIButton>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import DomHelper from '../helpers/DomHelper';
import Component from 'vue-class-component';
import UIButton from './ui/UIButton';

@Component({
  components: {
    UIButton,
  },
  props: {
    saving: {
      type: Boolean,
      required: false,
    },
    back: {
      type: Boolean,
      default: true,
    },
    backRouterLink: {
      type: Object,
      required: false,
    },
    backText: {
      type: String,
      required: false,
    },
    actionRouterLink: {
      type: Object,
      required: false,
    },
    actionText: {
      type: String,
      required: false,
    },
    savingText: {
      type: String,
      required: false,
    },
    continueText: {
      type: String,
      required: false,
    },
    valid: {
      type: Boolean,
      required: false,
      default: true,
    },
    withContinue: {
      type: Boolean,
      required: false,
      default: false,
    },
    backOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    custom: {
      type: Boolean,
    },
    darkMode: {
      type: Boolean,
      required: false,
    },
  },
})
export default class BottomBar extends Vue {
  get disabled () {
    return this.saving || !this.valid;
  }

  mounted () {
    DomHelper.addClass(document.body, '-bottombar-active');
  }

  save () {
    if (!this.disabled) this.$emit('save');
  }

  saveAndExit () {
    this.$emit('saveAndExit');
  }

  backAction () {
    if (this.backRouterLink) {
      if (window._$previousRoute && window._$previousRoute.name === this.backRouterLink.name) this.$router.go(-1);
      else this.$router.push(this.backRouterLink);
    } else {
      this.$emit('cancel');
    }
  }

  destroyed () {
    DomHelper.removeClass(document.body, '-bottombar-active');
  }
}
</script>

<style lang="postcss" scoped>
@import "../styles";

.pusher {
  height: 15rem;
}

.bottom-bar {
  @apply --panelDropShadow;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: var(--spacingSm);
  background-color: var(--colorWhite);
  display: flex;
  z-index: var(--zBottomBar);
  align-items: center;

  @apply --modalDropShadow;

  & .slot {
    display: none;

    @media (--tablet) {
      display: block;
      flex: 1 1 75%;
    }
  }

  & .mobileslot {
    display: block;
    flex: 1 0 0;
  }

  & .buttons {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;

    & > * {
      margin-right: var(--spacingSm);

      &:last-child {
        margin-right: 0;
      }
    }

    &.grid {
      display: grid;
      flex: 1 1 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--spacingSm);

      & button {
        margin-right: 0;
        min-width: auto !important;
        padding-left: var(--spacingXS);
        padding-right: var(--spacingXS);
      }

      @media (--tablet) {
        display: flex;
        justify-content: flex-end;

        & button {
          margin-right: var(--spacingSm);
          padding: var(--spacingSm) var(--spacingMd);

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  & .save {
    min-width: 17rem;
  }

}

.bottom-bar.dark {
  background-color: var(--darkModeColorDarkBackground) !important;
  color: var(--darkModeColorText) !important;
}

.bottom-bar.dark .buttons .back {
  background-color: var(--darkModeColorDarkBackground) !important;
  color: var(--darkModeColorText) !important;
}
</style>

<style lang="postcss">
@import "../styles";

body.-bottombar-active .zEWidget-launcher {
  bottom: 78px !important;
  @media (--tablet) {
    bottom: 98px !important;
  }
}
</style>
